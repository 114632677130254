import MonacoEditor, { EditorDidMount } from '@monaco-editor/react';
import codeShift from 'jscodeshift';
import Highlighter from 'monaco-jsx-highlighter';

import './CodeEditor.css';
import './syntax.css';

interface CodeEditorProps {
  initialValue: string;
  onChange(value: string): void;
  theme: string;
  editorRef: React.MutableRefObject<any>;
  isHorizontal: 'vertical' | 'horizontal';
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  onChange,
  initialValue,
  theme,
  editorRef,
  isHorizontal,
}) => {
  const onEditorDidMount: EditorDidMount = (getValue, monacoEditor) => {
    editorRef.current = monacoEditor;
    monacoEditor.onDidChangeModelContent(() => {
      onChange(getValue());
    });

    monacoEditor.getModel()?.updateOptions({ tabSize: 2 });

    const highlighter = new Highlighter(
      // @ts-ignore
      window.monaco,
      codeShift,
      monacoEditor
    );

    highlighter.highLightOnDidChangeModelContent(
      () => {},
      () => {},
      undefined,
      () => {}
    );
  };

  return (
    <div className={`editor-wrapper-${isHorizontal}`}>
      <MonacoEditor
        editorDidMount={onEditorDidMount}
        value={initialValue}
        theme={theme}
        language="javascript"
        height="100%"
        width="100%"
        options={{
          wordWrap: 'on',
          minimap: { enabled: false },
          showUnused: false,
          folding: false,
          lineNumbersMinChars: 3,
          fontSize: 16,
          scrollBeyondLastLine: false,
          automaticLayout: true,
        }}
      />
    </div>
  );
};

export default CodeEditor;
