import * as esbuild from 'esbuild-wasm';

export const unpkgPathPlugin = () => {
  return {
    name: 'unpkg-path-plugin',
    setup(build: esbuild.PluginBuild) {
      // Handle root entry file of 'index.js'
      build.onResolve({ filter: /(^index\.js$)/ }, () => {
        return { path: 'index.js', namespace: 'a' };
      });

      // Handle relative paths in a module
      build.onResolve({ filter: /^\.+\// }, (args: any) => {
        // Önceki URL oluşturma mantığını düzelt
        return {
          namespace: 'a',
          // `args.resolveDir` bir URL'den ziyade bir dosya yolu olduğu için,
          // onu doğrudan bir URL'ye eklemek yerine, 'https://unpkg.com/' URL'sini
          // temel alarak yeni bir URL oluşturun.
          path: new URL(args.path, `https://unpkg.com/${args.resolveDir}/`).href,
        };
      });

      // Handle main file of a module
      build.onResolve({ filter: /.*/ }, async (args: any) => {
        // Eğer modülün ana dosyası çözümleniyorsa, direkt olarak unpkg.com adresini kullan
        return { namespace: 'a', path: `https://unpkg.com/${args.path}` };
      });
    },
  };
};
