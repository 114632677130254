import IconButton from '@material-ui/core/IconButton';
import DarkIcon from '@material-ui/icons/Brightness4Rounded';
import LightIcon from '@material-ui/icons/Brightness7Rounded';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncreaseRounded';
// import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircleRounded';
// import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircleRounded';
import React from 'react';

import './Header.css';

interface HeaderProps {
  isDarkMode: boolean;
  darkLightHandle: () => void;
  onFormatClick: () => void;
  isHorizontal: 'vertical' | 'horizontal';
  horizantalVerticalHandle: () => void;
}
const Header: React.FC<HeaderProps> = ({
  isDarkMode,
  darkLightHandle,
  onFormatClick,
  isHorizontal,
  horizantalVerticalHandle,
}) => {
  const createdByStyle: any = {
    display: "inline-flex",
    'float': "right",
    alignItems: "center",
    height: "100%",
    paddingRight: "16px",
    cursor: 'pointer'
  }
  return (
    <div className="editor-header">
      <IconButton title="Toggle light/dark theme">
        {isDarkMode && <DarkIcon onClick={darkLightHandle} />}
        {!isDarkMode && <LightIcon onClick={darkLightHandle} />}
      </IconButton>
      <IconButton title="Format Code">
        <FormatIndentIncreaseIcon onClick={onFormatClick} />
      </IconButton>
      <a href='https://github.com/GurcanH' target='_blank' style={createdByStyle}>Created by: Gurcan Hamali</a>
      {/* <IconButton title="Toggle light/dark theme">
        {isHorizontal === 'vertical' && (
          <SwapHorizontalCircleIcon onClick={horizantalVerticalHandle} />
        )}
        {isHorizontal === 'horizontal' && (
          <SwapVerticalCircleIcon onClick={horizantalVerticalHandle} />
        )}
      </IconButton> */}
    </div>
  );
};

export default Header;
