import { useState, useEffect, useRef } from 'react';
import prettier from 'prettier';
import parser from 'prettier/parser-babel';

import CodeEditor from '../components/CodeEditor/CodeEditor';
import Preview from '../components/Preview/Preview';
import Resizable from '../components/Resizable/Resizable';
import Header from '../components/Header/Header';
import bundle from '../bundler';

const Container: React.FC = () => {
  const editorRef = useRef<any>();

  const initialValue = `
  import React from 'react';
  import ReactDOM from 'react-dom';
  
  const bodyStyle = {
    fontFamily: "'Roboto', sans-serif;",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
    backgroundColor: "#f5f5f5",
    color: "#333",
    padding: "20px"
  }

  const containerStyle = {
    backgroundColor: "#ffffff",
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    width: "100%",
    maxWidth: "500px"
  }

  const pStyle = {
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "20px"
  }

  const Component = () => {
    return (
      <div style={bodyStyle}> 
        <div style = {containerStyle}>
          <h1 style={{fontSize: '24px', marginBottom: '20px'}}>
            Welcome to <span style={{color: '#007bff'}}>React Fiddle!</span>
          </h1>
          <p style={pStyle}>Simplicity Meets React! Dive into one of the most minimalistic React playgrounds available online.</p>
          <p style={pStyle}>📝 <strong>Single Page Magic:</strong> No clutter, no distractions. 
          Just write all your React code on a single page and see the results in real-time.</p>
          <p style={pStyle}>📦 <strong>Instant Library Imports:</strong> Want to try a new library? Simply add the import statement at the top. 
          We'll fetch it directly from NPM for you. </p>
          <p>To start, just change the code on the left panel, import your packages, and see the result here! </p>

        </div>
      </div>
    )
  }

  ReactDOM.render(<Component />, document.getElementById('root'))
  `;

  const [code, setCode] = useState('');
  const [err, setErr] = useState('');
  const [input, setInput] = useState(initialValue);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isHorizontal, setIsHorizontal] = useState<'vertical' | 'horizontal'>(
    'horizontal'
  );
  const darkLightHandle = () => {
    setIsDarkMode(!isDarkMode);
  };

  const horizantalVerticalHandle = () => {
    setIsHorizontal(isHorizontal === 'horizontal' ? 'vertical' : 'horizontal');
  };
  const onFormatClick = (ref: React.MutableRefObject<any>) => {
    const unformatted = ref.current.getModel().getValue();

    const formatted = prettier
      .format(unformatted, {
        parser: 'babel',
        plugins: [parser],
        useTabs: false,
        semi: true,
        singleQuote: true,
      })
      .replace(/\n$/, '');

    ref.current.setValue(formatted);
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      const output = await bundle(input);
      setCode(output.code);
      setErr(output.err);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [input]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header
        onFormatClick={() => onFormatClick(editorRef)}
        isDarkMode={isDarkMode}
        darkLightHandle={darkLightHandle}
        isHorizontal={isHorizontal}
        horizantalVerticalHandle={horizantalVerticalHandle}
      />
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: isHorizontal === 'horizontal' ? 'row' : 'column',
        }}
      >
        <Resizable direction={isHorizontal}>
          <CodeEditor
            isHorizontal={isHorizontal}
            editorRef={editorRef}
            initialValue={initialValue}
            onChange={(value) => setInput(value)}
            theme={isDarkMode ? 'dark' : 'light'}
          />
        </Resizable>
        <Preview code={code} err={err} />
      </div>
    </div>
  );
};

export default Container;
