import 'bulmaswatch/superhero/bulmaswatch.min.css';
import ReactDOM from 'react-dom';
import Container from './containers/Container';
import axios from 'axios';
import { useEffect } from 'react';

const App = () => {

  useEffect(()=> {

  axios.post('https://us-central1-grcn-react-fiddle.cloudfunctions.net/addClientInfo').then(function(response) {
      // console.log(response)
  });
  }, [])

  return (
    <div style={{ height: '100vh' }}>
      <Container />
    </div>
  );
};

ReactDOM.render(<App />, document.querySelector('#root'));
